<template>
  <div>
    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="20">
        <aCol :span="24">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> Nome do item </label>

            <a-input
              class="travel-input"
              placeholder="Escreva"
              v-decorator="[
                `name`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </aCol>

        <aCol :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> Status </label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              v-decorator="[
                `status`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of status"
                :key="index"
                :value="item.value"
                :txt="item.label"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </aCol>

        <aCol :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> Ordem do item </label>

            <a-input
              class="travel-input"
              type="number"
              placeholder="0,1,2,3..."
              v-decorator="[
                `item_order`,
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </aCol>
        <aCol :span="24">
          <CategoryEntryEventsModulesCustomFields
            v-if="category.id == 38"
            :form="form"
            :category="category"
            :entry="entry"
          />

          <CategoryEntryNotesCustomFields
            v-if="category.id == 21 || category.id == 19"
            :form="form"
            :category="category"
          />

          <CategoryEntryAirportsCustomFields
            v-if="category.id == 30"
            :form="form"
            :category="category"
          />

          <CategoryEntryExchangeCustomFields
            v-if="category.id == 36"
            :form="form"
            :category="category"
          />
        </aCol>

        <aCol class="a-center mt-20" :span="24">
          <a-button
            type="primary"
            :loading="loadingCreateCategory"
            html-type="submit"
          >
            {{ type === "create" ? "Cadastrar item" : "Atualizar item" }}
          </a-button>
        </aCol>
      </a-row>
    </a-form>
  </div>
</template>

<script>
// components
import CategoryEntryNotesCustomFields from "../sections/CategoryEntryNotesCustomFields.vue";
import CategoryEntryAirportsCustomFields from "../sections/CategoryEntryAirportsCustomFields.vue";
import CategoryEntryExchangeCustomFields from "../sections/CategoryEntryExchangeCustomFields.vue";
import CategoryEntryEventsModulesCustomFields from "../sections/CategoryEntryEventsModulesCustomFields.vue";

export default {
  name: "CreateEntryDrawer",
  props: {
    category: Object,
    entry: Object,
    type: String,
  },
  components: {
    CategoryEntryNotesCustomFields,
    CategoryEntryAirportsCustomFields,
    CategoryEntryExchangeCustomFields,
    CategoryEntryEventsModulesCustomFields,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loadingCreateCategory: false,
      theCategoryEntry: {},
      status: [
        { label: "Ativo", value: "Ativo" },
        { label: "Desativado", value: "Desativado" },
      ],
    };
  },
  mounted() {
    if (this.type === "create") this.form.setFieldsValue({ status: "Ativo" });

    if (this.type === "edit")
      setTimeout(() => {
        if (this.entry?.id) {
          this.$http
            .post(`/category-entry/details?id=${this.entry.id}`)
            .then(({ data }) => {
              this.theCategoryEntry = data;
              this.form.setFieldsValue({
                ...data.raw,
                airport_name: data.meta?.airport_name,
                slug_module: data.meta?.slug_module,
                module_itens_selector: ![undefined, ""].includes(
                  data.meta.module_itens_selector
                )
                  ? data.meta.module_itens_selector
                  : undefined,
                airport_is_international: data.meta?.airport_is_international,
                allow_send_notification_and_email:
                  data.meta?.allow_send_notification_and_email,
                responsible_user_role: data.meta?.responsible_user_role,
                currency_code: data.meta?.currency_code,
              });
            });
        }
      }, 100);
  },
  methods: {
    createEntry(values) {
      this.$http
        .post("/category-entry/create", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.loadingCreateCategory = false;
          this.openCreateCategory = false;
          this.$emit("onCreateCategoryEntry");
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loadingCreateCategory = false;
          this.openCreateCategory = false;
        });
    },
    editEntry(values) {
      values.module_itens_selector = values.module_itens_selector ?? "";
      this.$http
        .post("/category-entry/update", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.loadingCreateCategory = false;
          this.$emit("onEditCategoryEntry");
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loadingCreateCategory = false;
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        values.category_id = this.category.id;

        if (!err) {
          this.loadingCreateCategory = true;
          if (this.type === "create") this.createEntry(values);
          if (this.type === "edit") values.id = this.theCategoryEntry.id;
          values.user_id = this.theCategoryEntry.user.id;
          values.category_id = this.category.id;
          this.editEntry(values);
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped></style>
