var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Código de letras ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `currency_code`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `currency_code`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"BRL, USD, EUR..."}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }