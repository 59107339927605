var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Nome do Aeroporto ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `airport_name`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `airport_name`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',[_c('label',{class:'label'},[_vm._v(" Aeroporto internacional? ")]),_vm._v(" "),_c('a-checkbox',{staticClass:"f12",attrs:{"checked":_vm.isInternational},on:{"change":_vm.onChangeIsInternational}},[_vm._v("\n          "+_vm._s(_vm.isInternational ? "Sim" : "Não")+"\n          "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`airport_is_international`]),expression:"[`airport_is_international`]"}],staticClass:"none"})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }