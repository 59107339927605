<template>
  <aRow :gutter="20">
    <aCol :span="12">
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Código de letras </label>
        <a-input
          class="travel-input"
          placeholder="BRL, USD, EUR..."
          v-decorator="[
            `currency_code`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>
  </aRow>
</template>

<script>
export default {
  name: "CategoryEntryExchangeCustomFields",
  props: {
    form: Object,
    category: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass" scoped>
.label
  display: block
  z-index: 4
  font-size: 10px !important
  font-weight: 500
  color: #ccc
  transition: 0.3s
  line-height: 1
  overflow: hidden
  text-overflow: ellipsis
  width: 100%
  white-space: nowrap
</style>
