<template>
  <aRow :gutter="20">
    <aCol :span="12">
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Slug do módulo </label>
        <a-input
          class="travel-input"
          placeholder="Escreva"
          :disabled="entryId < 3173 ? true : false"
          v-decorator="[
            `slug_module`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="12">
      <aFormItem class="travel-input-outer">
        <label for :class="'filled'">Seletor de itens do módulo (opcional) </label>
        <aSelect
          class="travel-input"
          placeholder="Selecione"
          optionFilterProp="txt"
          allowSearch
          allowClear
          v-decorator="[
            `module_itens_selector`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
          style="width: 100%"
        >
          <a-select-option
            v-for="(item, index) of modules"
            :key="index"
            :value="item.value"
            :txt="item.name"
          >
            {{ item.name }}
          </a-select-option>
        </aSelect>
      </aFormItem>
    </aCol>
  </aRow>
</template>

<script>
import modules from "@/json/hayaModules.json";

export default {
  name: "CategoryEntryAirportsCustomFields",
  props: {
    form: Object,
    category: Object,
    entry: Object,
  },
  data() {
    return {
      entryId: 3173,
      modules,
    };
  },
  mounted() {
    this.entryId = parseInt(this.entry.id);

    const filterModules = this.modules.filter((item) => {
      return !["all"].includes(item.value);
    });

    this.modules = filterModules;
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.label
  display: block
  z-index: 4
  font-size: 10px !important
  font-weight: 500
  color: #ccc
  transition: 0.3s
  line-height: 1
  overflow: hidden
  text-overflow: ellipsis
  width: 100%
  white-space: nowrap
</style>
