<template>
  <div>
    <aRow :gutter="20">
      <aCol :span="12">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Nome do Aeroporto </label>
          <a-input
            class="travel-input"
            placeholder="Escreva"
            v-decorator="[
              `airport_name`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item>
          <label :class="'label'"> Aeroporto internacional? </label>
          <a-checkbox
            class="f12"
            :checked="isInternational"
            @change="onChangeIsInternational"
          >
            {{ isInternational ? "Sim" : "Não" }}
            <a-input class="none" v-decorator="[`airport_is_international`]" />
          </a-checkbox>
        </a-form-item>
      </aCol>
    </aRow>
  </div>
</template>

<script>
// json
import roles from "@/json/roles.json";

export default {
  name: "CategoryEntryAirportsCustomFields",
  props: {
    form: Object,
    category: Object,
  },
  data() {
    return {
      roles,
      isInternational: false,
      allowSendEmail: [
        { label: "Sim", value: "Sim" },
        { label: "Não", value: "Não" },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.isInternational =
        this.form.getFieldValue(`airport_is_international`) == 1 ? true : false;
    }, 500);
  },
  methods: {
    onChangeIsInternational(e) {
      this.isInternational = e.target.checked;
      this.form.setFieldsValue({ airport_is_international: e.target.checked });
    },
  },
};
</script>

<style lang="sass" scoped>
.label
  display: block
  z-index: 4
  font-size: 10px !important
  font-weight: 500
  color: #ccc
  transition: 0.3s
  line-height: 1
  overflow: hidden
  text-overflow: ellipsis
  width: 100%
  white-space: nowrap
</style>
