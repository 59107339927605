var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Slug do módulo ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `slug_module`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `slug_module`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva","disabled":_vm.entryId < 3173 ? true : false}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v("Seletor de itens do módulo (opcional) ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `module_itens_selector`,
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `module_itens_selector`,\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","allowSearch":"","allowClear":""}},_vm._l((_vm.modules),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value,"txt":item.name}},[_vm._v("\n          "+_vm._s(item.name)+"\n        ")])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }