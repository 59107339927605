var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Nome do item ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `name`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `name`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Status ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `status`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `status`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt"}},_vm._l((_vm.status),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value,"txt":item.label}},[_vm._v("\n              "+_vm._s(item.label)+"\n            ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Ordem do item ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `item_order`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `item_order`,\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"type":"number","placeholder":"0,1,2,3..."}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[(_vm.category.id == 38)?_c('CategoryEntryEventsModulesCustomFields',{attrs:{"form":_vm.form,"category":_vm.category,"entry":_vm.entry}}):_vm._e(),_vm._v(" "),(_vm.category.id == 21 || _vm.category.id == 19)?_c('CategoryEntryNotesCustomFields',{attrs:{"form":_vm.form,"category":_vm.category}}):_vm._e(),_vm._v(" "),(_vm.category.id == 30)?_c('CategoryEntryAirportsCustomFields',{attrs:{"form":_vm.form,"category":_vm.category}}):_vm._e(),_vm._v(" "),(_vm.category.id == 36)?_c('CategoryEntryExchangeCustomFields',{attrs:{"form":_vm.form,"category":_vm.category}}):_vm._e()],1),_vm._v(" "),_c('aCol',{staticClass:"a-center mt-20",attrs:{"span":24}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.loadingCreateCategory,"html-type":"submit"}},[_vm._v("\n          "+_vm._s(_vm.type === "create" ? "Cadastrar item" : "Atualizar item")+"\n        ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }